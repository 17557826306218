import {Center, Container, Heading, Text} from '@chakra-ui/react';

function AllInOneSolution() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='white'>
            <Heading>All-in-One Solution</Heading>
            <Center mt={5} display={"flex"} flexDir={"column"}>
                <Text>Storepro offers an all-in-one solution for businesses to thrive in the competitive eCommerce landscape. By choosing Storepro, you gain access to a single, integrated platform that provides everything you need to run your online store efficiently:</Text>
                <Text mt={2}><b>Time-Saving Convenience:</b> No need to juggle between multiple software or platforms. With Storepro's all-in-one solution, manage your online store, inventory, and customer relationships all in one place.</Text>
                <Text mt={2}><b>Seamless Integration:</b> Our platform seamlessly integrates all the components of your eCommerce business, ensuring smooth data flow and eliminating compatibility issues.</Text>
                <Text mt={2}><b>Scalability:</b> Whether you are a startup or an established enterprise, our all-in-one solution grows with your business. Enjoy the flexibility to add new features and expand your store as your needs evolve.</Text>
                <Text mt={2}><b>Cost-Effective:</b> By bundling all essential eCommerce tools into one platform, Storepro delivers cost savings without compromising on functionality and performance.</Text>
                <Text mt={2}>Unlock the true potential of your eCommerce business with Storepro's complete suite of tools and an all-in-one solution that simplifies operations, maximizes efficiency, and empowers you to achieve remarkable success in the digital marketplace. Experience the future of eCommerce today!</Text>
            </Center>
        </Container>
    );
}

export default AllInOneSolution;
