import {
    Button,
    ButtonGroup,
    Container,
    Divider,
    GridItem,
    IconButton,
    Link,
    SimpleGrid,
    Stack,
    Text,
} from '@chakra-ui/react';
import HeaderLogo from '../header/headerLogo';
import {
    FaInstagramSquare,
    FaMobileAlt,
    FaTwitterSquare,
    FaYoutubeSquare,
} from 'react-icons/fa';

function Footer() {
    return (
        <Container
            px={{ base: 18, md: 28 }}
            mx={0}
            width={"100%"}
            maxW={"100%"}
            background='brandBlack.300'
            borderTop={'1px solid #DDDDDD'}
        >
            <SimpleGrid w='100%' pt={10} columns={{ base: 2, md: 4 }} columnGap={20} rowGap={10}
                        py={{ base: '12', md: '16' }}>
                <GridItem colSpan={2}>
                    <Stack
                        spacing={{
                            base: '6',
                            md: '8',
                        }}
                        align='start'
                        borderBottom={{ base: '1px solid #DDDDDD', md: 'none' }}
                        pb={{ base: '10', md: '0' }}
                    >
                        <HeaderLogo />
                        <Text color='muted'>Your Complete eCommerce Solution!</Text>
                    </Stack>
                </GridItem>

                <Stack spacing='4' minW='36' flex='1'>
                    <Text fontSize='sm' fontWeight='semibold' color='subtle'>
                        More
                    </Text>
                    <Stack spacing='3' shouldWrapChildren>
                        <Link href={"/"}>
                            <Button variant='link'>Home</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>Solutions</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>Features</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>Pricing</Button>
                        </Link>
                    </Stack>
                </Stack>

                <Stack spacing='4' minW='36' flex='1'>
                    <Text fontSize='sm' fontWeight='semibold' color='subtle'>
                        More
                    </Text>
                    <Stack spacing='3' shouldWrapChildren>
                        <Link href={"/"}>
                            <Button variant='link'>Privacy</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>Terms</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>About</Button>
                        </Link>
                        <Link href={"/"}>
                            <Button variant='link'>Contact</Button>
                        </Link>
                    </Stack>
                </Stack>
            </SimpleGrid>
            <Divider />
            <Stack
                pt='8'
                pb='12'
                justify='space-between'
                direction={{
                    base: 'column-reverse',
                    md: 'row',
                }}
                align='center'
                borderTop={'1px solid #DDDDDD'}
            >
                <Text fontSize='sm' color='subtle'>
                    &copy; {new Date().getFullYear()} Storepro. All Rights Reserved.
                </Text>
                <ButtonGroup variant='ghost'>
                    <IconButton
                        as='a'
                        href='#'
                        target='_blank'
                        aria-label='Instagram'
                        icon={<FaInstagramSquare fontSize='1.25rem' />}
                    />
                    <IconButton
                        as='a'
                        href='#'
                        target='_blank'
                        aria-label='YouTube'
                        icon={<FaYoutubeSquare fontSize='1.25rem' />}
                    />
                    <IconButton
                        as='a'
                        href='#'
                        target='_blank'
                        aria-label='Twitter'
                        icon={<FaTwitterSquare fontSize='1.25rem' />}
                    />
                </ButtonGroup>
            </Stack>
        </Container>
    );
}

export default Footer;
