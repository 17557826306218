import {
    Box,
    Button,
    ButtonGroup,
    CloseButton,
    HStack,
    IconButton,
    Link,
    Spacer,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';

import { AiOutlineMenu } from 'react-icons/ai';
import { BiStore, BiUser } from 'react-icons/bi';
import { FaMobileAlt } from 'react-icons/fa';

import HeaderLogo from './headerLogo';

function Header() {
    const mobileNav = useDisclosure();

    return (
        <Box as='header' position='sticky' w='100%' top='0' zIndex='300' bg={'white'}>
            <Box
                as='nav'
                w='full'
                px={{ base: 18, md: 28 }}
                py={{ base: 4, md: 5 }}
            >
                <HStack alignItems='center' justifyContent='space-between' mx='auto'>
                    <Link href='/' title='Storepro'>
                        <HeaderLogo />
                    </Link>
                    <HStack spacing={4} display='flex' alignItems='center'>
                        <ButtonGroup
                            variant='outline'
                            size='sm'
                            display={{ base: 'none', md: 'inline-flex' }}
                            spacing={3}
                            color="gray.500"
                            fontSize="md"
                            _hover={{
                                color: "gray.800"
                            }}
                            _focus={{
                                boxShadow: "none"
                            }}
                        >
                            <Link href='/' style={{ textDecoration: 'none' }}>
                                <Button>
                                    Home
                                </Button>
                            </Link>
                            <Link href='/' style={{ textDecoration: 'none' }}>
                                <Button>
                                    Solutions
                                </Button>
                            </Link>
                            <Link href='/' style={{ textDecoration: 'none' }}>
                                <Button>
                                    Features
                                </Button>
                            </Link>
                            <Link href='/' style={{ textDecoration: 'none' }}>
                                <Button>
                                    Pricing
                                </Button>
                            </Link>
                        </ButtonGroup>

                        <Box display={{ base: 'inline-flex', md: 'none' }}>
                            <IconButton
                                aria-label='Open Menu'
                                fontSize='20px'
                                color='gray.800'
                                variant='outline'
                                icon={<AiOutlineMenu />}
                                onClick={mobileNav.onOpen}
                            />
                            <Box
                                pos='absolute'
                                top={0}
                                left={0}
                                right={0}
                                bg='white'
                                display={mobileNav.isOpen ? 'flex' : 'none'}
                                rounded='md'
                                shadow='md'
                                px={{ base: 18, md: 28 }}
                                py={{ base: 4, md: 5 }}
                            >
                                <Link href='/' title='Storepro' py={'5px'}>
                                    <HeaderLogo />
                                </Link>

                                <Spacer />

                                <VStack
                                    flexDirection='column'
                                    alignItems={'end'}
                                    spacing={3}
                                >
                                    <IconButton
                                        aria-label='Open Menu'
                                        fontSize='20px'
                                        color='gray.800'
                                        variant='outline'
                                        icon={<CloseButton />}
                                        onClick={mobileNav.onClose}
                                    />
                                    <Link href='/' style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="gray.500"
                                            fontSize="md"
                                            _hover={{
                                                color: "gray.800"
                                            }}
                                            _focus={{
                                                boxShadow: "none"
                                            }}
                                        >
                                            Home
                                        </Button>
                                    </Link>
                                    <Link href='/' style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="gray.500"
                                            fontSize="md"
                                            _hover={{
                                                color: "gray.800"
                                            }}
                                            _focus={{
                                                boxShadow: "none"
                                            }}
                                        >
                                            Solutions
                                        </Button>
                                    </Link>
                                    <Link href='/' style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="gray.500"
                                            fontSize="md"
                                            _hover={{
                                                color: "gray.800"
                                            }}
                                            _focus={{
                                                boxShadow: "none"
                                            }}
                                        >
                                            Features
                                        </Button>
                                    </Link>
                                    <Link href='/' style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="gray.500"
                                            fontSize="md"
                                            _hover={{
                                                color: "gray.800"
                                            }}
                                            _focus={{
                                                boxShadow: "none"
                                            }}
                                        >
                                            Pricing
                                        </Button>
                                    </Link>
                                </VStack>
                            </Box>
                        </Box>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    );
}

export default Header;
