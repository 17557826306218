import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors: {
        brand: {
            50: "#FDF3EA",
            100: "#FBE7D6",
            500: "#EC8936",
            600: "#D47B30",
            700: "#BC6D2B",
            800: "#A55F25",
            900: "#8D5220"
        },
        brandBlack: {
            100: "#FFFFFF",
            300: "#F7F7F7",
            500: "#EBEBEB",
            700: "#717171",
            900: "#222222"
        }
    }
};

const customTheme = extendTheme(config);

export default customTheme;
