import Header from '../partials/common/header/header';
import Hero from '../partials/home/hero';
import Footer from '../partials/common/footer/footer';
import Solutions from "../partials/home/solutions";
import AllInOneSolution from "../partials/home/allInOneSolution";
import Features from "../partials/home/features";
import {Divider} from "@chakra-ui/react";
import Testimonials from "../partials/home/testimonials";

function Home() {
    return (
        <>
            <Header/>
            <Hero/>
            <Solutions/>
            <AllInOneSolution/>
            <Divider/>
            <Features/>
            <Testimonials/>
            <Footer/>
        </>
    );
}

export default Home;
