import {
    Container,
    Heading,
    SimpleGrid,
    Text
} from '@chakra-ui/react';
import SolutionCard from './solutionCard';

function Solutions() {
    return (
        <Container py={10} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brand.500'>
            <Heading color={'white'}>eCommerce Suite</Heading>
            <Text mt={5} color={'white'}>Discover the power of Storepro's comprehensive eCommerce suite that empowers businesses to thrive in the digital marketplace. Our advanced software includes a wide range of essential components, designed to streamline and enhance every aspect of your online store.</Text>
            <SimpleGrid w='100%' py={10} columns={{ base: 2, md: 3 }} columnGap={10} rowGap={10}>
                <SolutionCard
                    name="Online Store Setup"
                    description="Seamlessly launch your eCommerce store with our user-friendly website builder. Choose from customizable templates, create product categories, and set up payment and shipping options effortlessly."
                />
                <SolutionCard
                    name="Product Management"
                    description="Effortlessly manage your product catalog with ease. Add, edit, and organize products, showcase stunning images, and provide detailed product descriptions to engage your customers."
                />
                <SolutionCard
                    name="Payment Gateways"
                    description="Offer a seamless and secure payment experience to your customers. Our eCommerce suite integrates with leading payment gateways, allowing smooth transactions and building trust with your buyers."
                />
                <SolutionCard
                    name="Inventory Management"
                    description="Stay on top of your inventory levels with real-time updates. Avoid stockouts and overselling, and streamline your order fulfillment process for improved customer satisfaction."
                />
                <SolutionCard
                    name="Order Processing"
                    description="Efficiently process orders, generate invoices, and track shipments with our user-friendly order management system. Simplify your workflow and provide a smooth purchasing experience."
                />
                <SolutionCard
                    name="Customer Relationship Management (CRM)"
                    description="Build lasting customer relationships with our CRM tools. Capture and analyze customer data, personalize communication, and enhance customer retention."
                />
            </SimpleGrid>
        </Container>
    );
}

export default Solutions;
