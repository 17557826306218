import { Image } from '@chakra-ui/react';

function HeaderLogo() {
    return (
        <Image
            height='30px'
            width='auto'
            src='/images/logos/storepro-logo.png'
            alt='Storepro'
        />
    );
}

export default HeaderLogo;
