import { Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import FeatureCard from "./featureCard";
import {FaHeadset} from "react-icons/fa";
import {BiPalette, BiWorld} from "react-icons/bi";
import {MdStorage} from "react-icons/md";
import {AiFillCreditCard, AiFillSafetyCertificate, AiOutlineFileDone, AiOutlineMobile} from "react-icons/ai";
import {HiOutlineChartBar} from "react-icons/hi";
import {RiSearchEyeLine} from "react-icons/ri";

function Features() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Empowering Your eCommerce Success</Heading>
            <Text mt={5}>Storepro offers a robust set of key features and functionalities, carefully curated to elevate your online store and drive business growth. With our comprehensive suite of tools, you can seamlessly manage every aspect of your eCommerce business and deliver exceptional customer experiences. Explore the array of features that make Storepro the ultimate choice for your eCommerce needs:</Text>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 2 }} columnGap={10} rowGap={10}>
                <FeatureCard
                    name="Customizable Templates"
                    description="Choose from a wide selection of professionally designed templates that can be easily customized to match your brand identity. Create a visually stunning and unique online store that captivates your customers from the moment they land on your website."
                    icon={<BiPalette/>}
                />
                <FeatureCard
                    name="Inventory Management"
                    description="Effortlessly manage your product inventory in real-time. Track stock levels, receive alerts for low inventory, and ensure smooth order fulfillment, preventing stockouts and overselling."
                    icon={<MdStorage/>}
                />
                <FeatureCard
                    name="Payment Gateways"
                    description="Offer a secure and seamless checkout experience to your customers. Storepro integrates with trusted payment gateways, providing a variety of payment options to suit your buyers' preferences."
                    icon={<AiFillCreditCard/>}
                />
                <FeatureCard
                    name="Order Processing"
                    description="Streamline your order processing workflow with ease. Manage orders efficiently, generate invoices, and update order statuses to keep your customers informed about their purchases."
                    icon={<AiOutlineFileDone/>}
                />
                <FeatureCard
                    name="Customer Support Tools"
                    description="Deliver top-notch customer support with our integrated tools. Engage with customers through live chat, support tickets, or contact forms, addressing their queries and concerns promptly."
                    icon={<FaHeadset/>}
                />
                <FeatureCard
                    name="Analytics and Reporting"
                    description="Make data-driven decisions to grow your business with our robust analytics and reporting features. Gain valuable insights into sales trends, customer behavior, and website performance."
                    icon={<HiOutlineChartBar/>}
                />
                <FeatureCard
                    name="SEO Optimization"
                    description="Optimize your online store for search engines and boost organic traffic. Storepro's SEO tools help improve your website's visibility, making it easier for potential customers to find you online."
                    icon={<RiSearchEyeLine/>}
                />
                <FeatureCard
                    name="Mobile Responsiveness"
                    description="Ensure a seamless shopping experience for customers on all devices. Storepro's mobile-responsive design ensures your website looks great and functions flawlessly on smartphones and tablets."
                    icon={<AiOutlineMobile/>}
                />
                <FeatureCard
                    name="Multi-Channel Selling"
                    description="Expand your reach and sell across multiple channels effortlessly. Storepro integrates with popular marketplaces and social media platforms, enabling you to reach a broader audience."
                    icon={<BiWorld/>}
                />
                <FeatureCard
                    name="Security and Data Protection"
                    description="Rest assured knowing that your online store and customer data are secure with Storepro's robust security measures and data protection protocols."
                    icon={<AiFillSafetyCertificate/>}
                />
            </SimpleGrid>
            <Text mt={10}>Experience the power of these advanced features and take your eCommerce business to new heights with Storepro. We provide you with the tools and resources to drive success in the competitive digital landscape, so you can focus on what matters most - growing your business and delighting your customers. Choose Storepro today and witness the transformation of your online store into a thriving eCommerce enterprise.</Text>
        </Container>
    );
}

export default Features;
