import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import TestimonialCard from './testimonialCard';

function Testimonials() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.900'>
            <Heading color={'white'}>Testimonials</Heading>
            <SimpleGrid w='100%' pt={10} columns={1} columnGap={20} rowGap={10}>
                <TestimonialCard
                    name="Rahul Mehta"
                    description="Storepro has been a game-changer for my online business. With their customizable templates, I created a stunning website that perfectly reflects my brand. The inventory management feature keeps me organized, and the payment gateways ensure smooth transactions. My sales have skyrocketed, all thanks to Storepro!"
                />
                <TestimonialCard
                    name="Priya Sharma"
                    description="Running an online store was daunting until I found Storepro. Their order processing system simplified my workflow, and the customer support tools helped me provide exceptional service. The analytics feature allowed me to make data-driven decisions, leading to increased conversions. I'm grateful for Storepro's all-in-one solution!"
                />
                <TestimonialCard
                    name="Rohan Patel"
                    description="Storepro's SEO optimization boosted my store's visibility, resulting in higher search rankings and increased website traffic. The mobile responsiveness ensured a seamless shopping experience for my customers across devices. Storepro's user-friendly interface and CRM tools have been instrumental in building lasting customer relationships."
                />
                <TestimonialCard
                    name="Ayesha Khan"
                    description="As a multi-channel seller, Storepro's integration with various platforms has been a game-changer. I expanded my reach and gained more customers through marketplaces and social media. The security measures and data protection offered by Storepro gave me peace of mind. It's no wonder my eCommerce success has soared!"
                />
            </SimpleGrid>
        </Container>
    );
}

export default Testimonials;
